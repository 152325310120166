import React, { useState, useEffect } from "react";

import {
  Container,
  DatePicker,
  Icon,
  TextInput,
} from "../../..";



import css from "./style.module.scss";
import Lottie from "react-lottie-player";
import lottieJson from "../../../../lotties/engine.json";

import { Button } from "antd";
import { connect } from "react-redux";

import moment from "moment";
import { handleInputChange } from "../../../../utils";

import _ from "lodash";

const AdditionalInfoRaw = (props) => {
  const [loading, setLoading] = useState(true);

  const effectiveDate = props.effectiveDate;

  useEffect(() => {
    new Promise((resolve) => setTimeout(resolve, 1200)).then(() => {
      setLoading(false);
    });
  }, []);
  const params = JSON.parse(localStorage.getItem("universal_params"));

  params.dependents = params.dependents ? JSON.parse(params.dependents) : [];
  const spouse = props.applicants.spouse;
  const dependents = props.applicants.dependents || [];
  const isValid = () => {
    let spouseValid = true;
    if (
      spouse &&
      (!spouse.first_name || !spouse.last_name || !spouse.date_of_birth)
    ) {
    }

    let dependentsValid = true;

    dependents.forEach((d) => {
      if (!d.first_name || !d.last_name || !d.date_of_birth) {
        dependentsValid = false;
      }
    });

    return !spouseValid || !dependentsValid || !props.city;
  };

  return loading ? (
    <Container
      style={{
        display: "flex",
        width: "100vw",
        marginTop: "60px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Lottie
        loop
        animationData={lottieJson}
        play
        style={{ width: 300, height: 300 }}
      />
    </Container>
  ) : (
    <Container className={css.additionalInfoContainer}>
      <Container className={css.additionalInfoIconAndTitle}>
        <Container className={css.additionalInfoIcon}>
          <Icon icon="additional-info" />
        </Container>
        <Container className={css.additionalInfoTitle}>
          Enter your personal info:
        </Container>
      </Container>
      <Container className={css.inputs}>
        <Container className={css.applicantContainer}>
          <Container className={css.applicantTitle}>
            <Icon
              fill="#27A0FF"
              className={css.applicantIcon}
              icon="user-circle"
            />{" "}
            Main Applicant:
          </Container>
          <Container>
            <Container>
              <Container className={css.birthdateContainer}>
                <DatePicker
                  name="test-enrollment-enrollment-tools-additionalinfo-input-datebirth"
                  value={props.date_of_birth}

                    disabledDate={(m) => {
                      const lowerLimit = moment().add(- (Number(params.age) + 1), "years");
                      const upperLimit = moment().add(- Number(params.age), "years");
                      if ( m < lowerLimit || m > upperLimit ) {
                        return true;
                      } else {
                        return false;
                      }
                    }}

                  defaultPickerValue={moment().add(
                    -(Number(params.age) * 366),
                    "days"
                  )}
                  onChange={(e) => {
                    props.setAdditionalInfo({ key: "date_of_birth", value: e });
                  }}
                  before={"Date Of Birth"}
                  focusedlabel="Date of Birth"
                />
              </Container>
            </Container>
            <Container></Container>
          </Container>
        </Container>

        {spouse && (
          <Container className={css.applicantContainer}>
            <Container className={css.applicantTitle}>
              <Icon
                fill="#27A0FF"
                className={css.applicantIcon}
                icon="spouse-circle"
              />{" "}
              Spouse:
            </Container>
            <Container>
              <Container>
                <Container className={css.firstNameInputContainer}>
                  <TextInput
                    onChange={(e) => {
                      props.setAdditionalInfo({
                        key: "spouse",
                        value: {
                          ...props.spouse,
                          first_name: handleInputChange(e),
                        },
                      });
                    }}
                    value={props.spouse.first_name}
                    focusedlabel="Fist Name"
                    placeholder="First Name"
                  />
                </Container>
                <Container className={css.lastNameInputContainer}>
                  <TextInput
                    onChange={(e) => {
                      props.setAdditionalInfo({
                        key: "spouse",
                        value: {
                          ...props.spouse,
                          last_name: handleInputChange(e),
                        },
                      });
                    }}
                    value={props.spouse.last_name}
                    placeholder="Last Name"
                    focusedlabel="Last Name"
                  />
                </Container>
                <Container className={css.birthdateContainer}>
                  <DatePicker
                      disabledDate={(m) => {
                        const lowerLimit = moment().add(- (Number(spouse.age) + 1), "years");
                        const upperLimit = moment().add(- Number(spouse.age), "years");
                        if (m < lowerLimit || m > upperLimit) {
                          return true;
                        } else {
                          return false;
                        }
                      }}

                    defaultPickerValue={moment().add(
                      -(Number(spouse.age) * 366),
                      "days"
                    )}
                    value={props.spouse.date_of_birth}
                    onChange={(e) => {
                      props.setAdditionalInfo({
                        key: "spouse",
                        value: { ...props.spouse, date_of_birth: e },
                      });
                    }}
                    before={"Date Of Birth"}
                    focusedlabel="Date of Birth"
                  />
                </Container>
              </Container>
              <Container></Container>
            </Container>
          </Container>
        )}
        {dependents.map((dep, ind) => {
          return (
            <Container className={css.applicantContainer}>
              <Container className={css.applicantTitle}>
                <Icon
                  fill="#27A0FF"
                  className={css.applicantIcon}
                  icon="dependent-circle"
                />{" "}
                Dependent {ind + 1}:
              </Container>
              <Container>
                <Container>
                  <Container className={css.firstNameInputContainer}>
                    <TextInput
                      value={dependents[ind].first_name}
                      before="First Name"
                      onChange={(e) => {
                        dependents[ind].first_name = handleInputChange(e);
                        props.setAdditionalInfo({
                          key: "dependents",
                          value: dependents,
                        });
                      }}
                    />
                  </Container>
                  <Container className={css.lastNameInputContainer}>
                    <TextInput
                      value={dependents[ind].last_name}
                      before="Last Name"
                      onChange={(e) => {
                        dependents[ind].last_name = handleInputChange(e);
                        props.setAdditionalInfo({
                          key: "dependents",
                          value: dependents,
                        });
                      }}
                    />
                  </Container>
                  <Container className={css.birthdateContainer}>
                    <DatePicker
                      disabledDate={(m) => {
                        const lowerLimit = moment().add(- (Number(dep.age) + 1), "years");
                        const upperLimit = moment().add(- Number(dep.age), "years");
                        if (m < lowerLimit || m > upperLimit) {
                          return true;
                        } else {
                          return false;
                        }
                      }}

                      value={dependents[ind].date_of_birth}
                      before={"Date Of Birth"}
                      defaultPickerValue={moment().add(
                        -(Number(dep.age) * 366),
                        "days"
                      )}
                      onChange={(e) => {
                        dependents[ind].date_of_birth = e;

                        props.setAdditionalInfo({
                          key: "dependents",
                          value: dependents,
                        });
                      }}
                      focusedlabel="Date of Birth"
                    />
                  </Container>
                </Container>
              </Container>
            </Container>
          );
        })}
      </Container>
      <Container className={css.lastInputs}>
        <Container className={css.householdContainer}>
          <Container className={`${css.householdIconAndTitle}`}>
            <Container className={`${css.householdIcon} `}>
              <Icon icon="building" />
            </Container>
            <Container>
              <Container
                style={{ marginLeft: "2px" }}
                className={css.householdTitle}
              >
                Complete Household Address:
              </Container>
            </Container>
          </Container>
          <Container className={css.addressInputs}>
            <Container className={css.addressCityInput}>
              <TextInput
                name="test-enrollment-enrollment-additionalinfo-input-city"
                value={props.city}
                onChange={(e) => {
                  props.setAdditionalInfo({
                    key: "city",
                    value: handleInputChange(e),
                  });
                }}
                placeholder="City"
                focusedlabel="City"
              />
            </Container>
          </Container>
        </Container>
        <Container className={css.coverageContainer}>
          <Container className={`${css.householdIconAndTitle}`}>
            <Container className={`${css.householdIcon} `}>
              <Icon icon="heart-schedule" />
            </Container>
            <Container
              style={{ marginLeft: "2px" }}
              className={css.householdTitle}
            >
              Select the Coverage Start Date:
            </Container>
          </Container>

          <Container className={css.coverageDates}>
            <Container className={css.coverageDateInput}>
              <DatePicker
                disabledDate={(m) => {
                  const now = moment(new Date());
                  if (now >= m || now.add(90, "day") <= m) {
                    return true;
                  } else {
                    return false;
                  }
                }}

                before="Coverage Start Date"
                className={css.datePicker}
                onChange={(v) => {
                  props.setAdditionalInfo({
                        key: "effectiveDate",
                        value: v,
                      });
              }}
                value={effectiveDate}
              />
            </Container>

            {/* <Container className={css.coverageDateInput}>
                <TextInput  
                  onChange={(e) => {
                    props.setAdditionalInfo({
                      key: "draftDate",
                      value: handleInputChange(e),
                    }); }}
                  value={draftDate}
                  before="Desired Draft Date"
                /> 
              </Container> */}
          </Container>
        </Container>
      </Container>


      <Container className={css.buttonsContainer}>
        <Container className={css.previousButton}>
          <Button
            onClick={() => {
              props.previous && props.previous();
            }}
            type="primary"
            shape="round"
          >
            Previous
          </Button>
        </Container>
        <Container className={css.nextButton}>
          <Button
            disabled={isValid()}
            onClick={() => {
              props.next && props.next();
            }}
            type="primary"
            shape="round"
          >
            Next
          </Button>
        </Container>
      </Container>
    </Container>
  );
};

const mapStateToProps = ({
  agentMetadata,
  baseUrl,
  enrollment,
  applicants,
}) => {
  return {
    applicants,
    agentMetadata,
    baseUrl,
    enrollment,
    ...enrollment.universal.additionalInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAdditionalInfo: (payload) =>
      dispatch({ type: `SET_ADDITIONAL_INFO_UNIVERSAL`, payload }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalInfoRaw);
