import React from "react";
import css from "./style.module.scss";

import { Container, Header, Icon } from "../../..";
import { Steps } from "antd";
import EnrollmentSumaryUniversal from "../summary/index";

const { Step } = Steps;

const MasterPage = ({ children, currentStep, setCurrentStep }) => {
  const steps = [
    {
      title: <Container className={css.step}>Eligibility</Container>,
    },
    {
      title: <Container className={css.step}>Additional Info</Container>,
    },
    {
      title: <Container className={css.step}>Checkout</Container>,
    },
  ];

  return (
    <Container className={css.mainContainer}>
      <Container className={css.header}>
        <Container className={css.logoContainer}>
          <Icon fill="#fff" icon="enrollment-logo" />
        </Container>

        <Container className={css.stepsContainer}>
          <Steps
            size="small"
            className={css.steps}
            current={currentStep}
            type="navigation"
            onChange={(e) => setCurrentStep(e)}
          >
            {steps.map((item, pos) => (
              <Step key={"step" + pos + "ane"} title={item.title} disabled={pos > currentStep}/>
            ))}
          </Steps>
        </Container>
      </Container>
      <Container className={css.summaryContainer}>
        <EnrollmentSumaryUniversal />
      </Container>
      <Container className={css.contentContainer}>{children}</Container>
    </Container>
  );
};

export default MasterPage;
