import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import EnrollmentPlatform from "../../../components/universal-enrollment-platform";
import SEO from "../../../components/seo";

import { platform, PlatformContext } from "../../../context";

const Enrollment = () => {
    const data = useStaticQuery(graphql`
    query uniquequery {
      site {
        siteMetadata {
          agent {
            assets {
              image
            }
            id
            name
            phone
            whatsapp
            email
            website
            title
            imageSrc
            calendly
            bio
          }
        }
      }
    }
  `);

    return (
        <PlatformContext.Provider
            value={{
                quotes: false,
                enrollment: true,
            }}
        >
            <EnrollmentPlatform data={data}/>
        </PlatformContext.Provider>
    );
};

export default Enrollment;
